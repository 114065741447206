import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
const logo = require('./logo.png')

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agreed) {
      setMessage('Please agree to receive email notifications.');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, {
        name,
        email
      });
      if (res.status == 201) {
        setMessage('Thanks for subscribing!');
      }
    } catch (error) {
      setMessage(error.response.data.error);
    }
  }

  return (
    <div className="App">
      <Container className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
        <img src={String(logo)} alt="Proof of Print Logo" className='logo' style={{ width: "300px", height: "300px" }} />
        <h1 className='text-uppercase col-10 col-md-6'>The web3-native shop for museum-grade archival prints.</h1>
        <form onSubmit={handleSubmit}>
          <input className='name-field col-12' type="text" onChange={(e) => setName(e.target.value)} placeholder="Enter your name" required />
          <input
            class='email-field col-12'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email to be notified of our launch"
            required
          />
          <div className='checkbox-field form-check mt-3'>
            <input
              className='form-check-input'
              type="checkbox"
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
            />
            <label
              className="form-check-label">
              I agree to receiving Proof of Print related updates over email
            </label>
          </div>

          <Button type="submit" className='btn mt-3 mb-3' style={{ color: "black", borderRadius: 0, background: "white", border: "1px solid white" }}>Notify Me</Button>
        </form>
        {message && <p className='mb-3' style={{ fontSize: "16px" }}>{message}</p>}
      </Container>
    </div>
  );
}

export default App;
